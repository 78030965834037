import request from "@/utils/request";

/**
 * @description: 新增抄表记录
 * @param {object}
 */
export function addRecord(params) {
  return request({
    url: "/api/WaterRecord/AddRecord",
    method: "post",
    data: params,
  });
}

/**
 * @description: 查询某一水表抄表记录
 * @param {object}
 */
export function getRecordList(params) {
  return request({
    url: "/api/WaterRecord/ListWaterRecordByUnitInput",
    method: "get",
    params,
  });
}
/**
 * @description: 修改抄表读数
 * @param {object}
 */
export function modified(params) {
  return request({
    url: "/api/WaterRecord/Modified",
    method: "post",
    data: params,
  });
}
/**
 * @description: 获取最近一次可以修改的抄表记录
 * @param {object}
 */
export function getModified(WaterUnitId) {
  return request({
    url: "/api/WaterRecord/Modified",
    method: "get",
    params: {
      WaterUnitId,
    },
    headers: {
      extra: {
        noError: true, //不报错
      },
    },
  });
}
