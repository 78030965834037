<template>
  <div class="fullPage">
    <van-nav-bar title="抄表历史记录" left-arrow @click-left="$router.go(-1)" />
    <van-empty v-if="list.length === 0" description="" />
    <div class="list" style="overflow: hidden">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="getList"
      >
        <div class="item" v-for="(item, index) in list" :key="item.id">
          <div class="top d-flex col-center row-between">
            <div>
              <van-icon class="margin-r-4" name="clock-o" />{{
                item.createdTime | Date
              }}
            </div>
            <div>
              <van-button
                size="mini"
                type="primary"
                v-if="!item.paid"
                @click="toPayPage(item.id, item.estimatedPrice)"
              >
                支付
              </van-button>
              <van-tag v-else plain type="success">已支付</van-tag>
              <van-button
                size="mini"
                type="warning"
                v-if="index === 0 && !item.paid"
                @click="toEditPage()"
              >
                修改
              </van-button>
            </div>
          </div>
          <div class="bottom d-flex row-between" v-if="item.billingType === 0">
            <div>
              <div class="title">上次读数</div>
              <div class="margin-t-15" style="text-align: center">
                <span class="num">{{ item.lastDosage }}</span>
              </div>
            </div>
            <div>
              <div class="title">本次读数</div>
              <div class="margin-t-15" style="text-align: center">
                <span class="num">{{ item.dosage }}</span>
              </div>
            </div>
            <div>
              <div class="title">用量</div>
              <div class="margin-t-15" style="text-align: center">
                <span class="num">{{ item.dosage - item.lastDosage }}</span>
              </div>
            </div>
            <div>
              <div class="title">金额(元)</div>
              <div class="margin-t-15" style="text-align: center">
                <span class="num">{{ item.estimatedPrice / 100 }}</span>
              </div>
            </div>
          </div>
          <div class="bottom d-flex row-around" v-else>
            <div>
              <div class="title">通知单编号</div>
              <div class="margin-t-15" style="text-align: center">
                <span class="num">{{ item.noticeSheetId }}</span>
              </div>
            </div>
            <div>
              <div class="title">金额(元)</div>
              <div class="margin-t-15" style="text-align: center">
                <span class="num">{{ item.estimatedPrice / 100 }}</span>
              </div>
            </div>
            <!-- <div class="padding-x-20 padding-b-10">
              应收：{{ $price(item.estimatedPrice) }}元
            </div>
            <div class="padding-x-20 padding-b-20">
              通知单编号：{{ item.noticeSheetId }}
            </div> -->
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
import { getRecordList } from "@/apis/water-record.js";
export default {
  name: "used-water-history",
  data() {
    return {
      id: 0,
      list: [],
      loading: false,
      finished: false,
      total: 0,
      SkipCount: 0,
    };
  },
  created() {},
  methods: {
    getList() {
      let id = this.$route.query.id;
      this.id = id;
      getRecordList({
        WaterUnitId: this.id,
        MaxCount: 20,
        SkipCount: this.SkipCount,
      }).then((res) => {
        if (res.succeeded) {
          this.list.push(...res.data.items);
          this.total = res.data.totalCount;
          this.loading = false;
          this.SkipCount += 20;

          if (this.list.length >= this.total) {
            this.loading = false;
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    toPayPage(id, price) {
      this.$router.push({
        path: `/payment-method?id=${id}&price=${price}`,
      });
    },
    toEditPage() {
      this.$router.push({
        path: `/edit-record?id=${this.id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fullPage {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f6fafd !important;
}
.list {
  .item {
    margin: 15px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 5px 5px 1px #eee;
    padding: 0 10px;
    .top {
      font-size: 15px;
      color: #aaa;
      padding: 15px 10px;
    }

    .bottom {
      border-top: 1px solid #eee;
      padding: 15px;

      .title {
        font-size: 14px;
        color: #777;
      }
      .num {
        font-size: 16px;
        margin-right: 6px;
      }
      .unit {
        font-size: 12px;
        color: #777;
      }
    }
  }
}
</style>
